// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-jsx": () => import("/opt/build/repo/src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-tag-jsx": () => import("/opt/build/repo/src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */),
  "component---src-templates-category-jsx": () => import("/opt/build/repo/src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-pages-blog-jsx": () => import("/opt/build/repo/src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("/opt/build/repo/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-experiments-jsx": () => import("/opt/build/repo/src/pages/experiments.jsx" /* webpackChunkName: "component---src-pages-experiments-jsx" */),
  "component---src-pages-leathercraft-jsx": () => import("/opt/build/repo/src/pages/leathercraft.jsx" /* webpackChunkName: "component---src-pages-leathercraft-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("/opt/build/repo/src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-resume-jsx": () => import("/opt/build/repo/src/pages/resume.jsx" /* webpackChunkName: "component---src-pages-resume-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

