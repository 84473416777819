/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// require("prismjs/themes/prism-tomorrow.css")

// require( "./src/components/layout.scss")
require("@fortawesome/fontawesome-free/css/all.min.css")